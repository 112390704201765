import { InnerHtml, Title } from '@components';
import { StaticLayout } from '@features';
import { wrapper } from '@store';
import { withRedux } from '@utils';
import { NextSeo } from 'next-seo';
import React from 'react';
import { useStatic } from 'src/services';

const Index = ({ data }: any) => {
  return (
    <>
      <NextSeo
        title={`${data?.title} - ${process.env.NEXT_PUBLIC_SITENAME}`}
        description={process.env.NEXT_PUBLIC_DESCRIPTION}
        canonical={process.env.NEXT_PUBLIC_SEO_HOST}
        openGraph={{
          type: 'website',
          url: process.env.NEXT_PUBLIC_SEO_HOST,
          site_name: process.env.NEXT_PUBLIC_SITENAME,
          title: data?.title,
          description: data?.title,
          images: [{ url: `${process.env.NEXT_PUBLIC_HOST}/images/common/og-default.png` }],
        }}
      />
      <StaticLayout>
        <Title className="text-[#0B3262] text-2xl lg:text-3xl">{data?.title}</Title>
        <InnerHtml className="mt-7 text-gray-700" data={data?.description} />
      </StaticLayout>
    </>
  );
};
1234;

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  withRedux(
    async (context) => {
      const { getStaticByType } = useStatic();
      const { slug } = context.query;
      try {
        const { data } = await getStaticByType(String(slug));
        return { props: { data } };
      } catch (error) {
        return { notFound: true };
      }
    },
    { store },
  ),
);

export default Index;
